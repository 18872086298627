<template>
  <div>
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Commission report</div>
          <span class="subtitle-1 light-grey">
            Overview of commission to influencers
          </span>
        </div>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              outlined
              color="primary"
              @click="
                filtersTmp = JSON.parse(JSON.stringify(filters));
                filterDialog = true;
              "
            >
              <v-icon small>fal fa-filter</v-icon>
            </v-btn>
          </template>
          <span>Filter</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <div class="text-right">
          <v-btn color="primary" @click="showUpfrontDialog = true"
            >Create commission</v-btn
          >
        </div>
        <div class="text-right total-shown">
          {{ transactions.length }} out of {{ totalAmount | local_numbers }}
        </div>
        <commission-data-table
          :items="transactions"
          :loading="loading"
          pagination
          :has-less="page > 1"
          :has-more="hasMore"
          :total="total"
          :page="page"
          @changePage="_getTransactions($event)"
        ></commission-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="filterDialog" max-width="550px">
      <v-form @submit.prevent="filter()">
        <v-card>
          <v-card-title class="headline" primary-title>
            Filter rapport
          </v-card-title>
          <v-card-text>
            <v-select
              v-model="filtersTmp.statuses"
              :items="statuses"
              class="mr-10 filter-select width"
              item-text="name"
              item-value="value"
              clearable
              label="Status"
            ></v-select>
            <!-- <v-select
                            v-model="filtersTmp.type"
                            :items="types"
                            class="mr-10 filter-select width"
                            item-text="name"
                            item-value="value"
                            clearable
                            label="Type"
                        ></v-select> -->
            <influencer-autocomplete
              v-model="filtersTmp.influencer_id"
              :return-object="false"
            ></influencer-autocomplete>
            <campaign-autocomplete
              v-model="filtersTmp.campaign_id"
              :return-object="false"
              :display-draft="false"
            ></campaign-autocomplete>
            <date-picker
              v-model="filtersTmp.start_date"
              label="Start date"
              cancel-button
              reset-button
            ></date-picker>
            <date-picker
              v-model="filtersTmp.end_date"
              label="End date"
              cancel-button
              reset-button
            ></date-picker>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit" @click="filter()">
              Filter
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="showUpfrontDialog" max-width="550px">
      <v-form @submit.prevent="validateUpfrontPayment()">
        <v-card>
          <v-card-title>
            <div>
              <div class="headline">Create advance commission</div>
              <span class="subtitle-1 light-grey">
                Advance payment for specific influencer.
                <a
                  href="https://intercom.help/Make-influence-com/da/articles/8592373-tips-til-upfront-kampagner"
                  target="_blank"
                  >Read more</a
                >
              </span>
            </div>
          </v-card-title>
          <v-card-text v-if="!upfrontConfirm">
            <influencer-autocomplete
              v-model="upfrontPayment.influencer"
              :return-object="true"
              v-validate="'required'"
              name="Influencer"
              :error-messages="errors.collect('Influencer')"
            ></influencer-autocomplete>
            <campaign-autocomplete
              v-model="upfrontPayment.campaign_id"
              :return-object="false"
              :display-draft="false"
              name="Campaign"
            ></campaign-autocomplete>
            <v-text-field
              v-model="upfrontPayment.amount"
              type="number"
              v-validate="'required|min_value:1|max_value:100000'"
              suffix="kr"
              name="amount"
              hint="min. 1kr - max 100,000kr "
              label="Amount"
              data-vv-as="Beløb"
              :error-messages="
                amountValidation ? amountValidation : errors.collect('amount')
              "
            ></v-text-field>
          </v-card-text>
          <v-card-text v-if="upfrontConfirm" class="pa-2">
            <div class="upfront">
              <v-container fluid class="upfront-container">
                <v-layout row>
                  <div class="upfront-flex">
                    <div class="upfront-infographic">
                      <v-icon size="64">fal fa-money-bill</v-icon>
                      <div class="title">
                        {{
                          (upfrontPayment.amount ? upfrontPayment.amount : 0)
                            | local_numbers
                        }}kr
                      </div>
                    </div>
                  </div>
                  <div class="upfront-flex arrow">
                    <div class="upfront-infographic">
                      <v-icon size="55">fa fa-arrow-alt-right</v-icon>
                    </div>
                  </div>
                  <div class="upfront-flex">
                    <div class="upfront-infographic">
                      <user-avatar
                        class="user-avatar"
                        :user="upfrontPayment.influencer.user"
                        :size="64"
                      ></user-avatar>
                      <div class="subtitle-1">
                        {{ upfrontPayment.influencer.user.full_name }}
                      </div>
                    </div>
                  </div>
                </v-layout>
              </v-container>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="!upfrontConfirm" type="submit" color="primary">
              Create
            </v-btn>
            <v-btn
              v-if="upfrontConfirm"
              type="submit"
              color="success"
              :loading="upfrontLoading"
            >
              Confirm amount
            </v-btn>
            <v-btn text color="primary" @click="showUpfrontDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.upfront {
  border-radius: 4px;
  background-color: var(--v-secondary-base);
  padding: 34px 0;
  margin: 0 4px;

  .upfront-container {
    width: 85%;
    height: 100%;
    position: relative;
    margin: 20px auto;
  }

  .upfront-flex {
    display: flex;
    width: 40%;
    justify-content: center;

    &.arrow {
      width: 20%;
      align-items: center;
    }
  }

  .upfront-infographic {
    display: block;
    text-align: center;

    .user-avatar {
      position: relative;
      top: -1px;
      margin-bottom: 4px;
    }
  }
}
</style>

<script>
import { mapActions } from "vuex";
import local_numbers from "@/helpers/filters/local_numbers";
import search_filters_url from "@/helpers/mixins/search_filters_url";
import InfluencerAutocomplete from "../../../autocompletes/InfluencerAutocomplete";
import CampaignAutocomplete from "../../autocomplete/CampaignAutocomplete";
import CommissionDataTable from "../../data-tables/CommissionDataTable";
import DatePicker from "@/components/common/filters/DatePicker";
import UserAvatar from "../../../avatars/UserAvatar";

export default {
  components: {
    DatePicker,
    CommissionDataTable,
    InfluencerAutocomplete,
    CampaignAutocomplete,
    UserAvatar,
  },
  mixins: [search_filters_url],
  filters: { local_numbers },
  data() {
    return {
      loading: false,
      totalAmount: null,
      transactions: [],
      showUpfrontDialog: false,
      upfrontPayment: {},
      upfrontLoading: false,
      upfrontConfirm: false,
      amountValidation: null,
      filterDialog: false,
      filtersTmp: {},
      filters: {},
      page: 1,
      total: null,
      limit: 50,
      hasMore: true,
      statuses: [
        { name: "New", value: [1] },
        { name: "Ongoing", value: [2, 3, 4] },
        { name: "Done", value: [5] },
      ],
      types: [
        { name: "Conversion", value: 1 },
        { name: "Clicks", value: 2 },
        { name: "Impressions", value: 3 },
        { name: "Content", value: 4 },
        { name: "Upfront", value: 5 },
      ],
    };
  },
  computed: {
    filtersCount() {
      return Object.keys(this.filters).length;
    },
  },
  watch: {
    showUpfrontDialog(newValue) {
      if (!newValue) {
        this.amountValidation = null;
        this.errors.clear();
        this.upfrontConfirm = false;
      }
    },
    filters: {
      handler: function() {
        // reset
        this.transactions = [];
        this.page = 1;
        this.loading = false;
        this.hasMore = true;
        this.setUrlSearchFilters(this.filters);
        this._getTransactions();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("business", [
      "getTransactionsReport",
      "createUpfrontTransaction",
    ]),
    _getTransactions(page = 1) {
      const STATUSES = ["New", "Ongoing", "Ongoing", "Ongoing", "Done"];

      const TYPES = [
        "Conversions",
        "Clicks",
        "Impressions",
        "Content",
        "Upfront",
      ];

      if (this.loading) {
        return;
      }

      this.page = page;
      this.transactions = [];
      this.loading = true;

      const filters = this.cloneDeep(this.filters);

      const params = {
        ...filters,
        limit: this.limit,
        page: this.page,
        order_by: "date",
        order: "desc",
      };

      this.getTransactionsReport(params).then((response) => {
        response.data.forEach((item) => {
          const exists = this.transactions.filter(
            (itemTmp) => itemTmp.uuid === item.uuid
          );

          if (exists.length === 0) {
            item.status = STATUSES[item.status - 1];
            item.type = TYPES[item.type - 1];
            this.transactions.push(item);
          }
        });

        // If no more items
        if (response.data.length !== params.limit) {
          this.hasMore = false;
        }

        this.loading = false;
        this.total = response.lastPage;
        this.totalAmount = response.total;
      });
    },
    filter() {
      this.filters = this.cloneDeep(this.filtersTmp);
      this.filterDialog = false;
    },
    validateUpfrontPayment() {
      if (!this.upfrontConfirm) {
        return this.$validator.validateAll().then((result) => {
          if (result) {
            this.upfrontConfirm = true;
          }
        });
      }

      this.createUpfrontPayment();
    },
    createUpfrontPayment() {
      this.upfrontLoading = true;

      const params = {
        influencer_id: this.upfrontPayment.influencer.uuid,
        amount: parseFloat(this.upfrontPayment.amount),
      };

      if (this.upfrontPayment.campaign_id) {
        params.campaign_id = this.upfrontPayment.campaign_id;
      }

      this.createUpfrontTransaction(params).then(
        () => {
          this.setSnackSuccess("Saved");
          this._getTransactions();
          this.upfrontPayment = {};
          this.amountValidation = null;
          this.showUpfrontDialog = false;
          this.upfrontLoading = false;
          this.upfrontConfirm = false;
        },
        (errors) => {
          const arr = this.$errorHandler.getValidationErrors(errors);
          this.amountValidation = this.$errorHandler.getErrorMessage(
            arr,
            "amount"
          );
          this.setSnackError("Something went wrong");
          this.upfrontLoading = false;
          this.upfrontConfirm = false;
        }
      );
    },
  },
  created() {
    this.filters = this.getUrlSearchFilters();
  },
};
</script>
