<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            hide-default-footer
            :items-per-page="items.length"
        >
            <v-progress-linear
                slot="progress"
                color="primary"
                indeterminate
            ></v-progress-linear>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" :key="item.uuid">
                        <td>
                            <v-chip
                                color="secondary"
                                label
                                text-color="primary"
                                x-small
                            >
                                {{ item.status }}
                            </v-chip>
                        </td>
                        <td>{{ item.date | date_format("DD-MM-YYYY") }}</td>
                        <td>
                            <v-chip
                                color="secondary"
                                label
                                text-color="primary"
                                x-small
                            >
                                {{ item.type }}
                            </v-chip>
                        </td>
                        <td>{{ item.amount / 100 | local_numbers }}</td>
                        <td>
                            <router-link
                                v-if="item.influencer"
                                :title="item.influencer.user.full_name"
                                :to="{
                                    name: 'influencer',
                                    params: { id: item.influencer.uuid }
                                }"
                            >
                                {{ item.influencer.user.full_name }}
                            </router-link>
                        </td>
                        <td>
                            <router-link
                                v-if="item.campaign"
                                :title="item.campaign.name"
                                :to="{
                                    name: 'campaign',
                                    params: { id: item.campaign.uuid }
                                }"
                            >
                                {{ item.campaign.name }}
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
        <v-pagination
            v-model="currPage"
            class="my-2"
            :length="total"
            :total-visible="7"
            @input="handlePageChange"
        ></v-pagination>
    </div>
</template>

<style scoped>
    td {
        white-space: nowrap;
    }
</style>

<script>
    import local_numbers from "@/helpers/filters/local_numbers";
    import date_format from "@/helpers/filters/date_format";

    export default {
        filters: {
            local_numbers,
            date_format,
        },
        props: {
            items: {
                type: Array,
                required: false,
                default: () => {
                    return [];
                }
            },
            page: {
                type: Number
            },
            total: {
                type: Number
            },
            loading: {
                type: Boolean,
                required: false,
                default: false
            },
            pagination: {
                type: Boolean,
                required: false,
                default: false
            },
            hasLess: {
                type: Boolean,
                required: false,
                default: false
            },
            hasMore: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                headers: [],
                currPage: 1
            };
        },
        methods: {
            handlePageChange(value) {
                this.$emit("changePage", value);
            }
        },
        watch: {
            items() {
                this.currPage = this.page;
            },
        },
        mounted() {
            this.headers = [
                { text: "Status", value: "status", sortable: false },
                { text: "Date", value: "date", sortable: false },
                { text: "Type", value: "type", sortable: false },
                { text: "Commission", value: "amount", sortable: false },
                { text: "Influencer", value: "influencer.user.full_name", sortable: false },
                { text: "Campaign", value: "campaign.name", sortable: false },
            ];
        },
    }
</script>
