var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"hide-default-footer":"","items-per-page":_vm.items.length},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.uuid},[_c('td',[_c('v-chip',{attrs:{"color":"secondary","label":"","text-color":"primary","x-small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])],1),_c('td',[_vm._v(_vm._s(_vm._f("date_format")(item.date,"DD-MM-YYYY")))]),_c('td',[_c('v-chip',{attrs:{"color":"secondary","label":"","text-color":"primary","x-small":""}},[_vm._v(" "+_vm._s(item.type)+" ")])],1),_c('td',[_vm._v(_vm._s(_vm._f("local_numbers")(item.amount / 100)))]),_c('td',[(item.influencer)?_c('router-link',{attrs:{"title":item.influencer.user.full_name,"to":{
                                name: 'influencer',
                                params: { id: item.influencer.uuid }
                            }}},[_vm._v(" "+_vm._s(item.influencer.user.full_name)+" ")]):_vm._e()],1),_c('td',[(item.campaign)?_c('router-link',{attrs:{"title":item.campaign.name,"to":{
                                name: 'campaign',
                                params: { id: item.campaign.uuid }
                            }}},[_vm._v(" "+_vm._s(item.campaign.name)+" ")]):_vm._e()],1)])}),0)]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"primary","indeterminate":""},slot:"progress"})],1),_c('v-pagination',{staticClass:"my-2",attrs:{"length":_vm.total,"total-visible":7},on:{"input":_vm.handlePageChange},model:{value:(_vm.currPage),callback:function ($$v) {_vm.currPage=$$v},expression:"currPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }